export function useLogin(redirectTo: MaybeRefOrGetter<string | undefined | null>) {
  const auth = useAuthorizeStore();

  const { public: env } = useRuntimeConfig();

  const redirectCookie = useRedirectCookie();

  return function handleIDSPredirect(idsp: Idsp) {
    const redir = toValue(redirectTo);
    if (redir) {
      redirectCookie.value = redir;
    }

    auth.hasConfirmedSelectedOrganization = false;

    auth.logout(true);

    return navigateTo(getAuthUrl(idsp, env), { external: true });
  };
}
